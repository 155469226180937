<template>
  <div class="page margin-horizontal-large layout-vertical padding-vertical-small">
    <div class="layout-horizontal layout-middle layout-inflexible">
      <div class="font-size-extra-large margin-right">
        <back-button @click="handleBackClick"></back-button>
      </div>
      <ys-input class="input-search"
                placeholder="搜索报告模板"
                prefix-icon="el-icon-search"
                size="large"
                v-model="search"
                @input="handleSearchInput"
      ></ys-input>
      <ys-button
          class="margin-left"
          lighting
          @click="clickAddReportTemplate"
      >
        + 添加报告模板
      </ys-button>
    </div>
    <div class="padding-vertical-large layout-flexible" style="overflow: hidden;">
      <ys-infinite-scroll-view style="height: 100%;">
        <div class="list-item layout-horizontal layout-middle padding-horizontal-large padding-vertical"
             v-for="reportTemplate in reportTemplateList"
             :key="reportTemplate.id"
             @click="clickReportTemplate(reportTemplate)"
        >
          <span class="fas fa-file-medical"></span>
          <div class="margin-left layout-inflexible">
            <span class="font-size-medium">{{ reportTemplate.title }}</span>
          </div>
          <div class="layout-flexible">
          </div>
          <div>
            <ys-button
                class="btn-delete"
                round
                lighting
                type="secondary"
                @click.stop="clickDeleteReportTemplate(reportTemplate)"
            >
              <span class="far fa-trash-alt font-color-danger"></span>
            </ys-button>
          </div>
          <span class="font-color-placeholder fas fa-angle-right margin-left"></span>
        </div>
      </ys-infinite-scroll-view>
    </div>
    <report-template-picker-dialog
        :visible.sync="reportTemplatePickerDialogVisible"
        :organization-id="organizationId"
        title="选择健康报告模板"
        @pick="handleReportTemplatePicked"
    ></report-template-picker-dialog>
    <delete-dialog
        :visible.sync="deleteRemindDialogVisible"
        title="删除报告模板"
        message="您确定要删除该报告模板吗？"
        @confirm="confirmDeleteReportTemplate"
    ></delete-dialog>
  </div>
</template>

<script>
import YsInfiniteScrollView from "@/components/wedigets/YsInfiniteScrollView";
import BackButton from "@/components/backbutton/BackButton";
import YsInput from "@/components/wedigets/YsInput";
import YsButton from "@/components/wedigets/YsButton";
import httpapi from "@/assets/javascript/httpapi";
import ReportTemplatePickerDialog from "@/components/dialog/ReportTemplatePickerDialog";
import DeleteDialog from "@/components/dialog/DeleteDialog";

export default {
  name: "ReportList",
  mixins: [httpapi],
  components: {DeleteDialog, ReportTemplatePickerDialog, YsButton, YsInput, BackButton, YsInfiniteScrollView},
  props: {
    organizationId: Number,
  },
  data() {
    return {
      /**
       * api同步参数
       */
      busyLoadingCode: 0,

      /**
       * 搜索条件
       */
      search: null,

      /**
       * 报告模板列表
       */
      pageNum: 1,
      pageSize: 20,
      totalPages: 0,
      reportTemplateList: [],

      /**
       * 当前报告模板id
       */
      currentReportTemplateId: null,

      /**
       * 控制搜索的时间id
       */
      searchTimeoutId: null,

      /**
       * 添加报告模板
       */
      reportTemplatePickerDialogVisible: false,

      /**
       * 删除报告模板
       */
      deletingReportTemplate: null,
      deleteRemindDialogVisible: false,
    }
  },
  watch: {
    organizationId: {
      handler: function () {
        this.loadingCode++;
        this.search = '';
        if (this.organizationId) this.loadReportTemplateList()
      },
      immediate: true,
    }
  },
  mounted() {
    window.eventBus.$on('notify:report_template_add', this.handleReportTemplateAdded);
  },
  activated() {
    if (this.currentReportTemplateId) {
      this.loadReportTemplate(this.currentReportTemplateId);
      this.currentReportTemplateId = null;
    }
  },
  methods: {
    handleBackClick: function () {
      this.$router.back();
    },
    handleSearchInput: function () {
      this.loadingCode++;
      if (this.searchTimeoutId) {
        clearTimeout(this.searchTimeoutId);
      }
      this.searchTimeoutId = setTimeout(() => {
        clearTimeout(this.searchTimeoutId);
        this.loadReportTemplateList();
      });
    },
    clickAddReportTemplate: function () {
      this.reportTemplatePickerDialogVisible = true;
    },
    handleReportTemplatePicked: function (reportTemplateList) {
      this.$router.push({name: 'report_template', query: {id: reportTemplateList[0].id, isNew: true}});
    },
    clickReportTemplate(reportTemplate) {
      this.currentReportTemplateId = reportTemplate.id;
      this.$router.push({name: 'report_template', query: {id: reportTemplate.id}});
    },
    clickDeleteReportTemplate(reportTemplate) {
      this.deletingReportTemplate = reportTemplate;
      this.deleteRemindDialogVisible = true;
    },
    confirmDeleteReportTemplate() {
      this.removeReportTemplate(this.deletingReportTemplate);
    },
    handleReportTemplateAdded(reportTemplateId) {
      this.currentReportTemplateId = reportTemplateId;
    },
    enterBusy: function () {
      if (this.busyLoadingCode === this.loadingCode) {
        return false;
      }
      this.busyLoadingCode = this.loadingCode;
      return true;
    },
    existBusy: function () {
      this.busyLoadingCode = 0;
    },
    loadReportTemplateList: function (pageNum = 1) {
      if (!this.enterBusy()) return;
      this.$reqGet({
        path: `/report/template/list/${this.organizationId}/${pageNum}/${this.pageSize}` + (this.search ? `/${this.search}` : ''),
      })
          .returnWhenCodeMatches()
          .then(res => {
            this.pageNum = pageNum;
            this.totalPages = res.data.pages;
            this.reportTemplateList = pageNum === 1 ? [] : this.reportTemplateList;
            this.$appendAfter(this.reportTemplateList, res.data.list);
          })
          .catch(() => this.$message.error('加载失败'))
          .complete(() => this.existBusy());
    },
    loadMore: function () {
      if (this.pageNum < this.totalPages) this.loadReportTemplateList(this.pageNum + 1);
    },
    loadReportTemplate: function (reportTemplateId) {
      this.$reqGet({
        path: `/report/template/${reportTemplateId}`
      })
          .returnWhenCodeMatches()
          .then(res => {
            let reportTemplate = res.data;
            this.$appendBefore(this.reportTemplateList, [reportTemplate]);
          })
          .catch(() => this.$message.error('加载失败'));
    },
    removeReportTemplate: function (reportTemplate) {
      this.$reqDelete({
        path: `/report/template/${reportTemplate.id}`
      })
          .returnWhenCodeMatches()
          .then(() => {
            for (let n = this.reportTemplateList.length - 1; n >= 0; n--) {
              if (this.reportTemplateList[n].id === reportTemplate.id) {
                this.reportTemplateList.splice(n, 1);
                break;
              }
            }
            this.$message.success('删除成功');
          })
          .catch(() => this.$message.error('删除失败'));
    }
  }
}
</script>

<style scoped>

.page {
  height: 100vh;
  box-sizing: border-box;
}

.input-search {
  width: 300px;
}

.list-item .btn-delete {
  visibility: hidden;
}

.list-item:hover .btn-delete {
  visibility: visible;
}

</style>