import { render, staticRenderFns } from "./YsChecker.vue?vue&type=template&id=35f93836&scoped=true&"
import script from "./YsChecker.vue?vue&type=script&lang=js&"
export * from "./YsChecker.vue?vue&type=script&lang=js&"
import style0 from "./YsChecker.vue?vue&type=style&index=0&id=35f93836&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35f93836",
  null
  
)

export default component.exports