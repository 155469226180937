<template>
  <el-dialog
             :class="[
                 'ys-dialog',
                 {
                   'ys-dialog-size__small': size === 'small',
                 }
              ]"
             :width="width" :visible.sync="isVisible"
             :show-close="false"
             append-to-body
             modal-append-to-body
             @update:visible="(visible) => $emit('update:visible', visible)">
    <slot name="title" slot="title">
      <div class="padding">{{ title }}</div>
    </slot>
    <slot slot="default"></slot>
    <slot slot="footer" name="footer">
    </slot>
  </el-dialog>
</template>

<script>
export default {
  name: "YsDialog",
  props: {
    title: String,
    visible: Boolean,
    size: {
      type: String,
      default: 'normal',
    }
  },
  computed: {
    width() {
      switch (this.size) {
        case 'small':
          return '20%';
        default:
          return null;
      }
    }
  },
  data() {
    return {
      isVisible: false,
    }
  },
  watch: {
    visible: {
      handler() {
        this.isVisible = this.visible;
      },
      immediate: true,
    },
  }
}
</script>

<style scoped>

.ys-dialog >>> .el-dialog {
  border-radius: 16px;
  padding: 0px !important;
  overflow: hidden;
}

.ys-dialog >>> .el-dialog__header {
  margin: 0;
  padding: 0;
  background-color: #f091a6;
  color: white;
}

.ys-dialog >>> .el-dialog__body {
  margin: 0;
  padding: 0;
  background-color: white;
  min-height: 20vh;
}

.ys-dialog.ys-dialog-size__small>>>.el-dialog__body {
  min-height: 20vh;
}

.ys-dialog >>> .el-dialog__footer {
  background-color: #f1f1f1;
}


</style>
<style>

</style>