<template>
  <ys-dialog class="delete-dialog" :title="title" :visible.sync="isVisible" @update:visible="visible=>$emit('update:visible', visible)" size="small">
    <div class="font-size-extra-large padding">
      <span><i class="fas fa-exclamation-triangle font-color-warning"></i> {{message}}</span>
    </div>
    <div class="layout-horizontal layout-right padding padding-small" slot="footer">
      <ys-button type="secondary" lighting @click="handleCancelClick">{{cancelButtonText}}</ys-button>
      <ys-button type="danger" lighting class="margin-left" @click="handleConfirmClick"><span class="font-color-light">{{confirmButtonText}}</span></ys-button>
    </div>
  </ys-dialog>
</template>

<script>
import YsDialog from "@/components/wedigets/YsDialog";
import YsButton from "@/components/wedigets/YsButton";
export default {
  name: "DeleteDialog",
  components: {YsButton, YsDialog},
  props: {
    title: {
      type: String,
      default: '缮记 · 温馨提醒您',
    },
    message: String,
    visible: Boolean,
    cancelButtonText: {
      type: String,
      default: '取消'
    },
    confirmButtonText: {
      type: String,
      default: '删除'
    }
  },
  watch: {
    visible: {
      handler() {
        this.isVisible = this.visible;
      },
      immediate: true,
    }
  },
  data() {
    return {
      isVisible: false,
    }
  },
  methods: {
    handleCancelClick() {
      this.isVisible = false;
      this.$emit('update:visible', false);
      this.$emit('cancel');
    },
    handleConfirmClick() {
      this.isVisible = false;
      this.$emit('update:visible', false);
      this.$emit('confirm');
    }
  }
}
</script>

<style scoped>

.ys-dialog >>> .el-dialog__header {
  margin: 0;
  padding: 0;
  background-color: #6f1b2e !important;
  color: white;
}

</style>