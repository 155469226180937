<template>
  <ys-dialog :visible.sync="isVisible" @update:visible="visible=>$emit('update:visible', visible)">
    <div
        slot="title"
        class="padding-horizontal-large padding-vertical layout-horizontal layout-middle">
      <div class="layout-inflexible font-nowrap">{{ title }}</div>
      <div class="layout-flexible"></div>
      <div class="layout-inflexible">
        <span><i class="fas fa-plus"></i> 添加报告模板</span>
      </div>
    </div>
    <div>
      <ys-infinite-scroll-view @loadmore="loadMore" style="height: 50vh;">
        <div class="padding">
          <template v-if="selectedReportTemplateList.length > 0">
            <div class="font-size-small">已选择的报告模板：</div>
            <div class="layout-horizontal layout-wrap">
              <div
                  class="selected-report-template margin margin-small"
                  v-for="selectedReportTemplate in selectedReportTemplateList"
                  :key="selectedReportTemplate.id"
                  @click="handleReportTemplateClick(selectedReportTemplate)"
              >
                <span>{{ selectedReportTemplate.title }}</span>
                <span class="btn-delete-selected margin-left-small"><i class="el-icon-close"></i></span>
              </div>
            </div>
          </template>
          <div
              class="list-item layout-horizontal layout-middle"
              v-for="reportTemplate in reportTemplateList"
              :key="reportTemplate.id"
              @click="handleReportTemplateClick(reportTemplate)"
          >
            <ys-checker class="layout-inflexible" :value="reportTemplate.__selected" :disable="reportTemplate.__disable"></ys-checker>
            <div class="margin-left-large">
              <i class="fas fa-file-medical"></i>
            </div>
            <div class="margin-left" style="overflow: hidden">
              <div class="font-nowrap font-size-medium">{{reportTemplate.title}}</div>
              <div class="font-nowrap font-size-small font-color-secondary">{{reportTemplate.message}}</div>
            </div>
          </div>
        </div>
      </ys-infinite-scroll-view>
    </div>
    <div slot="footer">
      <ys-button type="secondary" lighting @click="handleCancelClick">取消</ys-button>
      <ys-button class="margin-left" lighting @click="handleConfirmClick">
        <span>确定</span>
        <span
            v-if="selectedReportTemplateList.length > 0">({{ selectedReportTemplateList.length }}/{{
            maxSelectedCount
          }})</span>
      </ys-button>
    </div>
  </ys-dialog>
</template>

<script>
import YsDialog from "@/components/wedigets/YsDialog";
import httpapi from "@/assets/javascript/httpapi";
import YsButton from "@/components/wedigets/YsButton";
import YsInfiniteScrollView from "@/components/wedigets/YsInfiniteScrollView";
import YsChecker from "@/components/wedigets/YsChecker";

function doLoadReportTemplateList(pageNum) {
  if (this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqGet({
    path: '/report/template/list/get',
    data: {
      organizationId: this.organizationId,
      pageNum,
      pageSize: 20,
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    this.$appendAfter(this.reportTemplateList, res.data.list);
    this.pageNum = pageNum;
    this.totalPages = res.data.pages;
    this.refreshSelectionState();
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

export default {
  name: "ReportTemplatePickerDialog",
  mixins: [httpapi],
  components: {YsChecker, YsInfiniteScrollView, YsButton, YsDialog},
  props: {
    visible: Boolean,
    organizationId: Number,
    title: {
      type: String,
      default: '选择健康报告模板',
    },
    maxSelectedCount: {
      type: Number,
      default: 1,
    },
    defaultSelectedIds: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      loadingCode: 1,
      busyLoadingCode: 0,

      reportTemplateList: [],
      pageNum: 1,
      totalPages: 0,

      selectedReportTemplateList: [],

      isVisible: false,
    }
  },
  watch: {
    organizationId: {
      handler() {
        this.loadingCode++;
        this.reportTemplateList = [];
        this.pageNum = 1;
        this.totalPages = 0;
        if (this.organizationId) {
          this.loadReportTemplateList();
        }
      },
      immediate: true,
    },
    visible: {
      handler() {
        this.isVisible = this.visible;
        if (this.isVisible) {
          this.selectedReportTemplateList = [];
          this.refreshSelectionState();
        }
      },
      immediate: true,
    }
  },
  methods: {
    loadReportTemplateList(pageNum = 1) {
      doLoadReportTemplateList.bind(this)(pageNum);
    },
    loadMore() {
      if (this.pageNum < this.totalPages) {
        this.loadReportTemplateList(this.pageNum + 1);
      }
    },
    handleReportTemplateClick(reportTemplate) {
      let selectedIndex = this.doGetSelectedIndex(reportTemplate);
      if (selectedIndex >= 0) {
        this.selectedReportTemplateList.splice(selectedIndex, 1);
      } else {
        if (this.selectedReportTemplateList.length < this.maxSelectedCount) {
          this.selectedReportTemplateList.push(reportTemplate);

          if(this.selectedReportTemplateList.length === 1 && this.maxSelectedCount === 1) {
            this.handleConfirmClick();
          }
        } else {
          this.$message.warning(`最多选择${this.maxSelectedCount}项`);
        }
      }
      this.refreshSelectionState();
    },
    handleCancelClick() {
      this.isVisible = false;
      this.$emit('update:visible', false);
    },
    handleConfirmClick() {
      if (this.selectedReportTemplateList.length === 0) {
        this.$message.warning('至少选择1项');
        return;
      }
      this.isVisible = false;
      this.$emit('update:visible', false);
      this.$emit('pick', this.selectedReportTemplateList);
    },
    doGetSelectedIndex(reportTemplate) {
      for (let n = 0; n < this.selectedReportTemplateList.length; n++) {
        if (this.selectedReportTemplateList[n].id === reportTemplate.id) {
          return n;
        }
      }
      return -1;
    },
    refreshSelectionState() {
      for (let reportTemplate of this.reportTemplateList) {
        let selected = false;
        let disable = false;
        for (let defaultSelectedId of this.defaultSelectedIds) {
          if (defaultSelectedId === reportTemplate.id) {
            selected = true;
            disable = true;
            break;
          }
        }
        if (!disable) {
          for (let selectedReportTemplate of this.selectedReportTemplateList) {
            if (selectedReportTemplate.id === reportTemplate.id) {
              selected = true;
              break;
            }
          }
        }
        reportTemplate.__selected = selected;
        reportTemplate.__disable = disable;
      }
    }
  }
}
</script>

<style scoped>

.list-item {
  width: auto;
}

.selected-report-template {
  background-color: #f091a6;
  color: white;
  padding: 8px 16px;
  border-radius: 100px;
}

.selected-report-template .btn-delete-selected {
  visibility: hidden;
}

.selected-report-template:hover .btn-delete-selected {
  visibility: visible;
}

</style>