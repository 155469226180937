<template>
  <i class="fa fa-arrow-left padding-right-small btn-back" @click="evt=>$emit('click', evt)"></i>
</template>

<script>
export default {
  name: "BackButton"
}
</script>

<style scoped>

.btn-back {
  cursor: pointer;
}

.btn-back:hover {
  color: #3a8ee6;
}

</style>