<template>
  <el-scrollbar ref="scrollbar" class="scrollbar">
    <slot></slot>
    <div v-infinite-scroll="loadMore" :infinite-scroll-disabled="infiniteScrollDisabled"
         :infinite-scroll-distance="infiniteScrollDistance" :infinite-scroll-immediate="true"></div>
  </el-scrollbar>
</template>

<script>
import infiniteScroll from "vue-infinite-scroll";

export default {
  name: "YsInfiniteScrollView",
  directives: {infiniteScroll},
  props: {
    infiniteScrollDisabled: {
      type: Boolean,
      default: false,
    },
    infiniteScrollDistance: {
      type: Number,
      default: 10,
    },
    listenReachEdge: Boolean,
  },
  data() {
    return {
      scrollTop: 0,
      reachTop: false,
      reachBottom: false,
    }
  },
  watch: {
    listenReachEdge: {
      handler() {
        if (this.$refs.scrollbar) {
          if (this.listenReachEdge) {
            this.$refs.scrollbar.wrap.onscroll = this.handleScroll;
          } else {
            this.$refs.scrollbar.wrap.onscroll = null;
          }
        }
      },
      immediate: true,
    }
  },
  methods: {
    loadMore: function () {
      this.$emit('loadmore');
    },
    handleScroll() {
      let scrollWrap = this.$refs.scrollbar.wrap;
      let maxScrollTop = scrollWrap.scrollHeight - scrollWrap.clientHeight;
      if (maxScrollTop - scrollWrap.scrollTop < 10) {
        if (!this.reachBottom) {
          this.reachBottom = true;
          this.$emit('reach:bottom');
        }
      } else {
        this.reachBottom = false;
      }
      if (scrollWrap.scrollTop < 10) {
        if (!this.reachTop) {
          this.reachTop = true;
          this.$emit('reach:top');
        }
      } else {
        this.reachTop = false;
      }
    },
    getScrollHeight() {
      return this.$refs.scrollbar.wrap.scrollHeight;
    },
    getScrollTop() {
      return this.$refs.scrollbar.wrap.scrollTop;
    },
    smoothScrollTo(top) {
      this.$refs.scrollbar.wrap.scrollTo({
        top,
        behavior: 'smooth'
      })
    },
    scrollTo(top) {
      this.$refs.scrollbar.wrap.scrollTo({
        top,
      })
    },
    smoothScrollToBottom() {
      let scrollWrap = this.$refs.scrollbar.wrap;
      let maxScrollTop = scrollWrap.scrollHeight - scrollWrap.clientHeight;
      if (maxScrollTop > 0) {
        this.smoothScrollTo(maxScrollTop);
      }
    },
    scrollToBottom() {
      let scrollWrap = this.$refs.scrollbar.wrap;
      let maxScrollTop = scrollWrap.scrollHeight - scrollWrap.clientHeight;
      if (maxScrollTop > 0) {
        this.scrollTo(maxScrollTop);
      }
    }
  },
  mounted() {
    if (this.listenReachEdge) {
      this.$refs.scrollbar.wrap.onscroll = this.handleScroll;
    }
  },
  destroyed() {
  },
  activated() {
    this.$refs.scrollbar.wrap.scrollTop = this.scrollTop;
  },
  deactivated() {
    this.scrollTop = this.$refs.scrollbar.wrap.scrollTop;
  },
  beforeDestroy() {

  }
}
</script>

<style scoped>

.scrollbar {
  width: inherit;
  height: inherit;
  overflow-x: hidden;
  overflow-y: hidden;
}

.scrollbar>>>.el-scrollbar__thumb {
  background-color: #bec0c0;
}



.header {
  height: 38px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f1f1f1;
}
</style>