<template>
  <div class="ys-check__wrapper" @click="handleClick">
    <div class="layout-horizontal layout-middle">
      <div class="ys-check font-size-extra-small"
           :class="{
      'is-active': this.isChecked,
      'is-disable': this.disable,
    }">
        <div class="fas fa-check" v-if="this.isChecked"></div>
      </div>
      <div class="margin-left" v-if="title">{{ title }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "YsChecker",
  props: {
    value: Boolean,
    disable: Boolean,
    title: String,
  },
  data() {
    return {
      isChecked: false,
    }
  },
  watch: {
    value: {
      handler() {
        this.isChecked = this.value;
      },
      immediate: true,
    }
  },
  methods: {
    handleClick() {
      if (this.disable) return;
      this.isChecked = !this.isChecked;
      this.$emit('input', this.isChecked);
    }
  }
}
</script>

<style scoped>

.ys-check__wrapper {
  cursor: pointer;
  user-select: none;
  display: inline-block;
  vertical-align: center;
}


.ys-check {
  background-color: #f1f1f1;
  width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  vertical-align: center;
  line-height: 20px;
  justify-content: center;
  color: #bec0c0;
  cursor: pointer;
  border: 5px solid #dadada;
  box-sizing: border-box;
}

.ys-check div {
  position: relative;
  top: -4px;
  left: -1px;
}

.ys-check.is-active {
  color: white;
  border: 5px solid #f091a6;
  background-color: #f091a6;
}

.ys-check.is-disable {
  opacity: .5;
}
</style>